import React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { getAccessToken } from 'application/contexts/AuthenticationContext';
import logger from 'helpers/logger';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { getNavigationData } from 'application/repositories/navigationRepository';
import { PageTypeMapFeature } from 'features/Mappers/PageType/PageTypeFeature';
import { PageContentResponse } from 'application/repositories/pageContentRepository';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import {
	getContactFormData,
	getSignupFormData,
} from 'application/repositories/formRepository';
import { ModalHelpFormFeature, ModalSignupFormFeature } from 'features/Modules';
import { getLanguages } from 'application/repositories/languagesRepository';
import { getLocations } from 'application/repositories/locationsRepository';
import { getCookie } from 'cookies-next';
import { getCheckoutStatus } from 'application/repositories/checkoutStatusRepository';

const Page: NextPage<PageContentResponse> = (pageContent) => {
	const {
		page,
		accessToken,
		restrictedPage,
		contactFormData,
		signupFormData,
		culture,
		host,
	} = pageContent ?? {};

	// Send to login page if it's a restricted page and user is not authenticated
	if (restrictedPage && !accessToken) {
		signIn('authServer');

		return null;
	}

	return (
		<LayoutFeature {...pageContent} accessToken={accessToken}>
			<PageTypeMapFeature
				{...page}
				accessToken={accessToken}
				culture={culture}
			/>
			{/* ContactForm / HelpForm to be enabled in a future iteration */}
			{/* <ModalHelpFormFeature
				accessToken={accessToken}
				culture={culture}
				host={host}
				{...contactFormData}
			/> */}
			<ModalSignupFormFeature
				accessToken={accessToken}
				culture={culture}
				host={host}
				{...signupFormData}
			/>
		</LayoutFeature>
	);
};

export const getServerSideProps: GetServerSideProps<any> = async ({
	params,
	preview,
	previewData: untypedPreviewData,
	req,
	locale,
}) => {
	const { host, page } = params;
	const url = page ? (page as string[]).join('/') : '';

	const previewData = untypedPreviewData as Models.PreviewData;

	try {
		const culture = locale === 'default' ? 'en' : locale;
		const accessTokenPromise = getAccessToken(req);
		const accessToken = (await accessTokenPromise) ?? null;
		const restrictedUrls = [
			'my-account',
			'basket',
			'delivery',
			'information',
			'payment',
			'receipt',
		];
		const restrictedPage = restrictedUrls.includes(url);

		// Abort if not authenticated and URL is a restricted page
		if (!accessToken && restrictedPage) {
			return {
				props: {
					accessToken,
					restrictedPage,
				},
			};
		}

		const closedUrls = [
			'basket',
			'delivery',
			'information',
			'payment',
			'receipt',
		];
		const closedPage = closedUrls.includes(url);
		const bpId = getCookie('bpId', { req })?.toString();

		// Redirect if shop is closed
		if (closedPage) {
			const checkoutStatus = await getCheckoutStatus(accessToken, bpId);

			if (checkoutStatus?.shopClosed) {
				return {
					redirect: {
						destination: checkoutStatus.shopClosedRedirect,
						permanent: false, // temporary redirect, so it's not cached
					},
				};
			}
		}

		const contentPromise = getPageContent({
			url,
			host,
			preview,
			previewData,
			accessToken,
			locale,
		});

		// ContactForm / HelpForm to be enabled in a future iteration
		// const contactFormData = await getContactFormData({
		// 	host,
		// accessToken,
		// 	locale: culture,
		// });

		const navigationData = await getNavigationData({
			host,
			accessToken,
			locale: culture,
		});

		const signupFormData = await getSignupFormData({
			host,
			accessToken,
			locale: culture,
		});

		const languageSelector = await getLanguages({
			host,
			locale: culture,
		});
		const locations = await getLocations({ host: host, locale: culture });
		const content = await contentPromise;

		return {
			props: {
				content,
				host,
				navigationData,
				accessToken,
				// contactFormData, // ContactForm/HelpForm to be enabled in a future iteration
				signupFormData,
				culture,
				languageSelector,
				locations,
			},
		};
	} catch (error) {
		const { statusCode, url } = error;
		if (statusCode === 404) {
			return {
				notFound: true,
			};
		}

		if (
			statusCode === 301 ||
			statusCode === 302 ||
			statusCode === 307 ||
			statusCode === 308
		) {
			return {
				redirect: {
					statusCode: statusCode,
					destination: url,
				},
			};
		}

		logger.error(error);
		throw new Error(error);
	}
};

export default Page;
