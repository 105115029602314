import logger from 'helpers/logger';
import { capitalizeFirstLetter } from 'helpers/string';
import * as PageTypes from 'features/PageTypes';
import { PageContentResponse } from 'application/repositories/pageContentRepository';

export const PageTypeMapFeature: React.FC<PageContentResponse['page']> = ({
	template,
	accessToken,
	culture,
	...page
}) => {
	const production = process.env.NODE_ENV === 'production';

	const featureName = `${capitalizeFirstLetter(template)}PageFeature`;

	const DynamicPageTemplate = PageTypes[featureName];

	const message = `PageType [${template}] is missing a React Component. Add missing export '${featureName}' from /features`;

	if (!DynamicPageTemplate && !production) {
		return <div>{message}</div>;
	}

	if (!DynamicPageTemplate && production) {
		logger.error(message);
		return null;
	}

	return (
		<DynamicPageTemplate
			{...page}
			accessToken={accessToken}
			culture={culture}
		/>
	);
};
