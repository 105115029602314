import { Main } from 'ui/components';

import { ModuleMapFeature } from 'features/Mappers/Module';

export const ContentPageFeature: React.FC<Umbraco.ContentPage> = ({
	properties,
	accessToken,
	culture,
}) => {
	const { pageHeader, pageSections } = properties ?? {};

	return (
		<Main
			header={pageHeader.map((headerModule, index) => (
				<ModuleMapFeature key={index} contentModule={headerModule} />
			))}
		>
			{pageSections.map((sectionModule, index) => (
				<ModuleMapFeature
					key={index}
					contentModule={sectionModule}
					accessToken={accessToken}
					culture={culture}
				/>
			))}
		</Main>
	);
};
