import { Main } from 'ui/components/4-habitats/Main';
import {
	ArticleHeader,
	ArticleByline,
	ArticleAuthor,
} from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';
import { ModuleMapFeature } from 'features/Mappers/Module';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import { useContext } from 'react';
export const ArticlePageFeature: React.FC<Umbraco.ArticlePage> = ({
	...props
}) => {
	const { author, pageSections } = props.properties ?? {};
	const localeContext = useContext(LocaleContext);
	return (
		<Main>
			<Container width="Slim">
				<ArticleHeader
					updateDate={localeContext.formatStringDate(
						props.updateDate,
					)}
					{...props.properties}
				/>
				{author && <ArticleByline {...author?.properties} />}
			</Container>
			{pageSections.map((pageSection, index) => (
				<ModuleMapFeature key={index} contentModule={pageSection} />
			))}
			<Container width="Slim">
				{author && <ArticleAuthor {...author?.properties} />}
			</Container>
		</Main>
	);
};
