import { Main } from 'ui/components/4-habitats/Main';
import { ArticleHeader, ArticleByline, ArticleAuthor, ArticleDate } from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';
import { ModuleMapFeature } from 'features/Mappers/Module';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import { useContext } from 'react';

export const NewsPageFeature: React.FC<Umbraco.NewsPage> = ({ properties }) => {
	const { newsTeaser, newsHeading, newsAuthor, newsDate, pageSections } = properties ?? {};
	const localeContext = useContext(LocaleContext);
	return (
		<Main>
			<Container width="Small">
				<ArticleHeader teaser={newsTeaser} heading={newsHeading} />
				<ArticleDate date={localeContext.formatStringDate(newsDate)} />
				{newsAuthor && <ArticleByline {...newsAuthor?.properties} />}
			</Container>
			{pageSections.map((pageSection, index) => (
				<ModuleMapFeature key={index} contentModule={pageSection} />
			))}
			<Container width="Small">{newsAuthor && <ArticleAuthor {...newsAuthor?.properties} />}</Container>
		</Main>
	);
};
